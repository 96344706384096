.banner {
    position: relative;
    margin: auto;
    z-index: 2;
    width: 100%;
    margin: 0;
    padding: $pad-medium $pad-normal;
    background: $brand-black;

    &__icon {
        color: $brand-plum;

        @media screen and (max-width: $bp-med) {
            display: none;
        }
    }

    &__inner {
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: $bp-med) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            max-width: $main-max-width;
        }
    }

    &__blurb {
        @include text(300, $color: $white, $inverted: true);
        margin-bottom: $pad-compact;
        line-height: 1.1;
        
        @media screen and (min-width: $bp-med) {
            margin: 0 auto 0 $pad-compact;
            text-align: left;
        }
    }

    &__link {
        @include heading(200, $color: $white, $inverted: true);

        @media screen and (min-width: $bp-med) {
            margin: 0 0 0 $pad-compact;
            flex: 0 0 auto;
        }
    }

    p {
        margin-bottom: 0;
        color: $brand-light-gray;
        font-family: $font-family-header;
    }
}

.banner--alert {
    background: #FEF9D9;

    .banner__blurb {
        @include text(300, $color: $brand-black, $inverted: false);
        line-height: 1.1;
    }

    .banner__link, a {
       color: $brand-black;
       text-decoration: underline;
    }

    .banner__icon {
       color: $brand-goldenrod;
    }
}
