.main {
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    &__inner {
        max-width: none;
        flex: 1 auto;
    }
}
