.links-list {
    background: $brand-off-white;
    padding: $pad-normal $pad-comfortable $pad-normal 4rem;

    &__divider {
        padding: 0 $pad-small;
        color: $brand-light-gray;
    }

    &__inner {
        list-style-type: none;
    }
}
