// 01 Settings
@import '01_settings/color.scss',
        '01_settings/type.scss',
        '01_settings/variables.scss';

// 02 Tools
@import '02_tools/functions.scss',
        '02_tools/mixins.scss',
        '02_tools/keyframes.scss';

// 03 Vendors
@import '03_vendors/focus-visible.scss',
        '03_vendors/include-media.scss';
        
// 04 Reset
@import '04_reset/reset.scss';

// 05 Base
@import '05_base/base.scss';

// 06 Components
@import '06_components/banner.scss';
@import '06_components/button.scss';
@import '06_components/call-to-action.scss';
@import '06_components/callout.scss';
@import '06_components/card.scss';
@import '06_components/contact-form.scss';
@import '06_components/footer.scss';
@import '06_components/form.scss';
@import '06_components/links-list.scss';
@import '06_components/navbar.scss';
@import '06_components/note.scss';
@import '06_components/page-header.scss';
@import '06_components/paginator.scss';
@import '06_components/section.scss';
@import '06_components/testimonial.scss';

// 07 Layouts
@import '07_layouts/main.scss';

// 08 Overrides
@import '08_overrides/404.scss';
@import '08_overrides/contact.scss';
@import '08_overrides/home.scss';
@import '08_overrides/thank-you.scss';
@import '08_overrides/splash.scss';
