.page-404 {
  background: $brand-light-gray;

  .join-us,
  .site-footer,
  footer {
    display: none;
  }
}

.text-404 {
  font-size: 130px;
  color: $secondary;
  display: block;
  margin-bottom: -60px;
  font-weight: map-get($font-weights, bold);
  text-align: center;
}

.block-404 {
  display: block;
  text-align: center;
  width: 100%;
  max-width: 500px;
  margin: auto;
  background: $brand-dark-royal-blue;
  padding: $pad-spacious $pad-normal;
  border-radius: $border-radius;
  
  h2,
  p {
    color: $brand-off-white;
  }

  h2 {
    margin-bottom: 0;
  }

  p {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .btn {
    margin-bottom: 10px;
  }
}