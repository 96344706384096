$font-family: freight-sans-pro, sans-serif;
$font-family-header: frank-new, sans-serif;

$font-weights: (
    light: 300, // New Frank, no i
    medium: 500, // New Frank & Freight Sans Pro, i
    bold: 700 // New Frank & Freight Sans Pro, no i
);

$default-font-size-text: 300;
$default-font-size-heading: 500;

$font-sizes: (
    100: 1rem,
    200: 1.3rem,
    300: 1.6rem,
    400: 1.85rem,
    500: 2.25rem,
    600: 2.8rem,
    700: 3.9rem,
);

$text-settings: (
    100: (
        font-family: $font-family,
        color: map-get($font-colors, normal),
        font-weight: map-get($font-weights, medium),
        font-size: map-get($font-sizes, 100),
        line-height: 1.5,
    ),
    200: (
        color: map-get($font-colors, normal),
        font-weight: map-get($font-weights, medium),
        font-size: map-get($font-sizes, 200),
        line-height: 1.5,
    ),
    300: (
        color: map-get($font-colors, normal),
        font-weight: map-get($font-weights, medium),
        font-size: map-get($font-sizes, 300),
        line-height: 1.5,
    ),
    400: (
        color: map-get($font-colors, normal),
        font-weight: map-get($font-weights, medium),
        font-size: map-get($font-sizes, 400),
        line-height: 1.5,
    ),
    500: (
        color: map-get($font-colors, normal),
        font-weight: map-get($font-weights, light),
        font-size: map-get($font-sizes, 500),
        line-height: 1.5,
    ),
);

$heading-settings: (
    100: (
        color: map-get($font-colors, normal),
        font-family: $font-family-header,
        font-weight: map-get($font-weights, bold),
        font-size: map-get($font-sizes, 100),
        line-height: 1.2,
        text-transform: uppercase,
        letter-spacing: 1px
    ),
    200: (
        color: map-get($font-colors, normal),
        font-family: $font-family-header,
        font-weight: map-get($font-weights, medium),
        font-size: map-get($font-sizes, 200),
        line-height: 1.2,
        text-transform: uppercase,
        letter-spacing: 1px
    ),
    300: (
        color: map-get($font-colors, normal),
        font-family: $font-family-header,
        font-weight: map-get($font-weights, medium),
        font-size: map-get($font-sizes, 300),
        line-height: 1.2,
        text-transform: uppercase,
        letter-spacing: 1px
    ),
    400: (
        color: map-get($font-colors, dark),
        font-family: $font-family-header,
        font-weight: map-get($font-weights, medium),
        font-size: map-get($font-sizes, 400),
        line-height: 1.2,
        text-transform: none,
        letter-spacing: 0
    ),
    500: (
        color: map-get($font-colors, dark),
        font-family: $font-family-header,
        font-weight: map-get($font-weights, medium),
        font-size: map-get($font-sizes, 500),
        line-height: 1.2,
        text-transform: none,
        letter-spacing: 0
    ),
    600: (
        color: map-get($font-colors, dark),
        font-family: $font-family-header,
        font-weight: map-get($font-weights, light),
        font-size: map-get($font-sizes, 600),
        line-height: 1.2,
        text-transform: none,
        letter-spacing: 0
    ),
    700: (
        color: map-get($font-colors, dark),
        font-family: $font-family-header,
        font-weight: map-get($font-weights, light),
        font-size: map-get($font-sizes, 700),
        line-height: 1.2,
        text-transform: none,
        letter-spacing: 0
    ),
);