@keyframes flip {
  0%   { transform: rotateX(0deg) scaleY(1); }
  100% { transform: rotateX(180deg) scaleY(-1); }
}

@keyframes show {
  0% { opacity: 0;  }
  100% { opacity: 1; }
}

@keyframes hide {
  0% { opacity: 1; }
  100% { opacity: 0; }
}